import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { Card } from '@twilio-paste/card';
import { FormInput, FormLabel } from '@twilio-paste/form';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';
import { Stack } from '@twilio-paste/stack';
import { SidebarCategoryRoutes } from '../../../constants';
import Changelog from '@twilio-paste/stack/CHANGELOG.md';
export const pageQuery = graphql`
  {
    allPasteLayout(filter: {name: {eq: "@twilio-paste/stack"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/layout/stack/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Stack" categoryRoute={SidebarCategoryRoutes.LAYOUT} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/layout/stack" storybookUrl="/?path=/story/layout-stack--stack" data={props.data.allPasteLayout.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Stack`}</h3>
        <Paragraph mdxType="Paragraph">
  {props.pageContext.frontmatter.description} It allows you to easily stack elements together with defined spacing
  between them. It can be set up either vertically or horizontally, and uses tokens for spacing.
        </Paragraph>
        <h4>{`Accessibility`}</h4>
        <p>{`By default, Stack has no accessibility concerns. While using Stack, it is up to you to manage the resulting
accessibility implications. You may want to consider how the stack affects readability due to ordering, and
the visual or content hierarchy of your page.`}</p>
        <h2>{`Examples`}</h2>
        <h3>{`Vertical Stack`}</h3>
        <p>{`Elements within the stack are placed vertically with bottom spacing. This is achieved by setting
`}<inlineCode parentName="p">{`orientation="vertical"`}</inlineCode>{`. A `}<inlineCode parentName="p">{`spacing`}</inlineCode>{` property is provided to let you to create space between each stacked element.
`}<inlineCode parentName="p">{`spacing`}</inlineCode>{` will take any `}<a parentName="p" {...{
            "href": "/tokens#spacings"
          }}>{`Spacing Token`}</a>{` as its value.`}</p>
        <LivePreview scope={{
          Button,
          Card,
          Heading,
          Paragraph,
          Stack
        }} language="jsx" mdxType="LivePreview">
  {`<Stack orientation="vertical" spacing="space60">
  <Card>
    <Heading as="h3" variant="heading30">What's new at Twilio</Heading>
    <Paragraph>Check out our newest product releases on the Twilio Changelog.</Paragraph>
    <Button variant="primary">Changelog</Button>
  </Card>
  <Card>
    <Heading as="h3" variant="heading30">Twilio Docs</Heading>
    <Paragraph>Dig into our API reference documentation and quickstarts. You're covered with everything from SDKs in your favorite languages to sample apps for web, iOS, and Android.</Paragraph>
    <Button variant="secondary">Explore the Docs</Button>
  </Card>
</Stack>`}
        </LivePreview>
        <h3>{`Horizontal Stack`}</h3>
        <p>{`Elements within the stack are placed horizontally with right spacing. This is achieved by setting
`}<inlineCode parentName="p">{`orientation="horizontal"`}</inlineCode>{`. A `}<inlineCode parentName="p">{`spacing`}</inlineCode>{` property is provided to let you to create space between each stacked element.
`}<inlineCode parentName="p">{`spacing`}</inlineCode>{` will take any `}<a parentName="p" {...{
            "href": "/tokens#spacings"
          }}>{`Spacing Token`}</a>{` as its value.`}</p>
        <LivePreview scope={{
          Anchor,
          Button,
          Stack
        }} language="jsx" mdxType="LivePreview">
  {`<Stack orientation="horizontal" spacing="space60">
  <Button variant="primary">Save</Button>
  <Button variant="secondary">Cancel</Button>
  <Anchor href="#">Close Project</Anchor>
</Stack>`}
        </LivePreview>
        <h3>{`Responsive Stack`}</h3>
        <p><inlineCode parentName="p">{`orientation`}</inlineCode>{` is a responsive props. You can provide it an array of orientation
values to change the orientation based on the screen sizes supported by your `}<a parentName="p" {...{
            "href": "/tokens/theme-package#theme-provider"
          }}>{`theme`}</a>{`.`}</p>
        <LivePreview scope={{
          Anchor,
          Button,
          Card,
          FormInput,
          FormLabel,
          Heading,
          Stack
        }} language="jsx" mdxType="LivePreview">
  {`<Stack orientation={['vertical', 'vertical', 'horizontal']} spacing="space60">
  <Card>
    <Heading as="h3" variant="heading30">1. Create a bot</Heading>
    <FormLabel htmlFor="unique_name" required>Unique Name</FormLabel>
    <FormInput id="unique_name" name="unique_name" type="text" placeholder="Your bot name" onChange="" />
  </Card>
  <Card>
    <Heading as="h3" variant="heading30">2. Copy messaging URL</Heading>
    <FormLabel htmlFor="messaging_url" required>Messaging URL</FormLabel>
    <FormInput id="messaging_url" name="messaging_url" type="url" value="https://channel.autopilot.twilio.com..." onChange="" readOnly />
  </Card>
</Stack>`}
        </LivePreview>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/stack
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Stack} from '@twilio-paste/stack';

<Stack orientation="vertical" spacing="space40">
  Foo
</Stack>;
`}</code></pre>
        <h4>{`Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`orientation`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ResponsiveValue<'horizontal', 'vertical'>`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`spacing`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/tokens/#spacings"
                }}>{`Spacing`}</a></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      